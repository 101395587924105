/* eslint-disable max-len */
import React from 'react'
import { PageProps } from 'gatsby'
import Banner from '../../components/Banner'
import Layout from '../../components/Layout/index'
import SEO from '../../components/Seo'
import FoundsSection from '../../components/FoundsSection'
import FoundsTypeSelector from '../../components/FoundsTypeSelector'
import foundsData from '../../assets/data/inter-asset/fundos.json'
import { IFoundOption } from './types'

import { IFound } from '../../types/types'
import { ICategoria } from '../../templates/fundosListados/types'

const AcessoAInformacao: React.FC<PageProps> = () => {
  const [ foundsList, setFoundsList ] = React.useState<ICategoria[]>(foundsData)
  const [ showOnlyListedFounds, setShowOnlyListedFounds ] = React.useState(false)
  const [ foundsOptions, setFoundsOptions ] = React.useState<IFoundOption[]>([
    {
      categoryId: '0',
      option: 'Todos',
      haveListedFounds: true,
      selected: true,
    },
  ])

  React.useEffect(() => {
    const params = new URLSearchParams(document.location.search)
    const selectedFoundCategoryQuery = params.get('category')
    let options: IFoundOption[] = foundsData.reduce((accumulator: IFoundOption[], found: ICategoria) => {
      const haveListedFounds = found.fundos.find((foundData: IFound) => {
        return foundData.isListed === true
      })
      accumulator.push({
        categoryId: found.idCategoria,
        option: `${found.segundoNome}`,
        haveListedFounds: haveListedFounds ? !!haveListedFounds : false,
        selected: false,
      })

      return accumulator
    }, [])

    if (selectedFoundCategoryQuery) {
      options = options.map((option: IFoundOption) => {
        if (option.option === selectedFoundCategoryQuery) {
          return { ...option, selected: true }
        }

        return option
      })

      options.unshift({
        categoryId: '0',
        option: 'Todos',
        haveListedFounds: true,
        selected: false,
      })
    } else {
      options.unshift({
        categoryId: '0',
        option: 'Todos',
        haveListedFounds: true,
        selected: true,
      })
    }
    setFoundsOptions(options)
  }, [])

  React.useEffect(() => {
    const filterSelectedOption = () => {
      const selectedOption = foundsOptions.find((option: IFoundOption) => option.selected === true)
      if (selectedOption && selectedOption?.categoryId !== '0') {
        const selectedCategory = foundsData.find((foundCategory: ICategoria) => {
          if (showOnlyListedFounds) {
            return foundCategory.idCategoria === selectedOption.categoryId && foundCategory.fundos.find((found: IFound) => {
              return found.isListed === true
            })
          } else {
            return foundCategory.idCategoria === selectedOption.categoryId
          }
        })
        selectedCategory && setFoundsList([ selectedCategory ])
      } else {
        setFoundsList(foundsData)
      }
    }

    filterSelectedOption()
  }, [ foundsOptions, showOnlyListedFounds ])

  return (
    <>
      <Layout>
        <SEO title='Produtos - Inter Asset' description='Conheça os produtos da Inter Asset: Fundos de Renda Fixa, Fundos Imobiliários, Fundos de Ações, Fundos de Previdência, Fiagro e muito mais!' />
        <Banner imageMobile='backgroundBannerTiposDeFundosMobile' imageDesktop='backgroundBannerTiposDeFundosDesktop'>
          <div className='container'>
            <div className='row text-white align-items-center'>
              <div className='col-12 col-md-8 col-lg-5 text-left'>
                <h1 className='fs-23 lh-40 fs-md-40 lh-md-50 fs-xl-48 lh-xl-60 text-white fw-600 f-sora mb-0'>Produtos</h1>
              </div>
            </div>
          </div>
        </Banner>
        <FoundsTypeSelector foundsOptionsList={foundsOptions} setFoundsOptions={setFoundsOptions} showOnlyListedFounds={showOnlyListedFounds} setShowOnlyListedFounds={setShowOnlyListedFounds} />
        <FoundsSection founds={foundsList} showOnlyListedFounds={showOnlyListedFounds} />
      </Layout>
    </>
  )
}

export default AcessoAInformacao
