import styled, { css } from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import { gray, white } from '../../styles/colors'

interface IButton {
  $active?: boolean;
}

const activeButton = css`
  color: ${white};
  border: 1px solid ${gray[550]};
  background-color: ${gray[750]};
`

const inactiveButton = css`
  color: ${gray[750]};
  border: 1px solid ${gray[550]};
  background-color: ${white};
`

export const FoundsTypeSelector = styled.div`
  background-color: ${gray[400]};
`

export const CategoryButtonGrid = styled.div`
  display: flex;
  white-space: nowrap;
  gap: 12px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (min-width: ${breakpoints.md}) {
    flex-wrap: wrap;
    overflow-x: none;
  }
`

export const ListedButtonGrid = styled.div`
  display: flex;
  white-space: nowrap;
  gap: 12px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (min-width: ${breakpoints.md}) {
    flex-wrap: wrap;
    gap: 16px;
    overflow-x: none;
  }
`

export const Button = styled.button<IButton>`
  padding: 8px 16px;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;

  &:disabled {
    opacity: .4;
    &:hover {
      opacity: .4;
      color: ${gray[750]};
    }
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 8px 12px;
  }

  ${(props: IButton) => props.$active ? activeButton : inactiveButton}

  &:hover {
    text-decoration: none;
    opacity: 0.9;
  }

  transition: ease-in .2s;
`
