import * as React from 'react'
import * as S from './styles'
import { IFound } from '../../types/types'

interface IFoundsList extends React.ComponentProps<'div'> {
  found: IFound;
  categoryName: string;
  slug: string;
  isListed?: boolean;
}

const FoundCard = ({ found, categoryName, slug, isListed }: IFoundsList) => {
  return (
    <S.Card>
      <div className='w-100'>
        <span className='fs-10 lh-12 fw-700 text-gray-750 font-inter category-background'>{categoryName}</span>
        {isListed && <span className='fs-10 lh-12 fw-700 text-gray-750 font-inter is-listed-background ml-2'>Fundo Listado</span>}
        <h3 className='fs-16 lh-20 fs-lg-24 lh-lg-30 fw-600 text-interasset-brand font-sora mt-2 mb-0'>{found.nome}</h3>
      </div>
      <div className='w-100'>
        <p className='fs-12 lh-15 fs-lg-14 lh-lg-17 text-gray-650 font-inter descricao mb-2'>{found.descricao}</p>
        <S.AccessButton href={`/${slug}`}>Acessar</S.AccessButton>
      </div>
    </S.Card>
  )
}

export default FoundCard
