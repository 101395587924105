/* eslint-disable max-len */
import * as React from 'react'

import * as S from './styles'
import { IFoundOption } from '../../pages/tipos-de-fundos/types'

interface IFoundsTypeSelector {
  foundsOptionsList: IFoundOption[];
  setFoundsOptions: React.Dispatch<React.SetStateAction<IFoundOption[]>>;
  showOnlyListedFounds: boolean;
  setShowOnlyListedFounds: React.Dispatch<React.SetStateAction<boolean>>;
}

function FoundsTypeSelector ({ foundsOptionsList, setFoundsOptions, showOnlyListedFounds, setShowOnlyListedFounds }: IFoundsTypeSelector) {
  const categoryHandleClick = (selectedFoundCategoryId: string) => {
    setFoundsOptions((foundsOptions: IFoundOption[]) => {
      return foundsOptions.map((foundOption: IFoundOption) => {
        if (foundOption.categoryId !== selectedFoundCategoryId) {
          foundOption.selected = false
        } else {
          foundOption.selected = true
        }
        return foundOption
      })
    })
  }

  const listedHandleClick = () => {
    if (showOnlyListedFounds === false) {
      foundsOptionsList.forEach((found: IFoundOption) => {
        if (found.selected === true) {
          if (found.haveListedFounds === true) {
            categoryHandleClick(found.categoryId)
          } else {
            categoryHandleClick('0')
          }
        }
      })
    }
    setShowOnlyListedFounds(!showOnlyListedFounds)
  }

  return (
    <S.FoundsTypeSelector>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12'>
            <S.CategoryButtonGrid className='fade-right fade-left mb-3'>
              {foundsOptionsList.map((found: IFoundOption, index: number) => (
                <S.Button key={`${found.option}-${index}`} $active={found.selected} onClick={() => categoryHandleClick(found.categoryId)} disabled={showOnlyListedFounds && !found.haveListedFounds}>{found.option}</S.Button>
              ))}
            </S.CategoryButtonGrid>
            <S.ListedButtonGrid className='fade-right fade-left'>
              <S.Button $active={showOnlyListedFounds} onClick={() => listedHandleClick()}>Fundos Listados</S.Button>
            </S.ListedButtonGrid>
          </div>
        </div>
      </div>
    </S.FoundsTypeSelector>
  )
}

export default FoundsTypeSelector
