/* eslint-disable max-len */
import * as React from 'react'
import FoundCard from '../FoundCard'
import * as S from './styles'
import DefaultCarousel from '../UI/Carousels/DefaultCarousel'

import useWidth from '../../hooks/useWidth'

import slugify from 'slugify'
import convertToSlug from '../../helpers/convertToSlug'
import { IFound } from '../../types/types'
import { ICategoria } from '../../templates/fundosListados/types'

const WIDTH_MD = 768

interface IFoundsList {
  found: ICategoria;
  showOnlyListedFounds: boolean;
}

const FoundsList = ({ found, showOnlyListedFounds }: IFoundsList) => {
  const [ hideCategory, setHideCategory ] = React.useState(false)
  const [ foundCategory, setFoundCategory ] = React.useState<ICategoria>(found)
  const [ foundsList, setFoundsList ] = React.useState<IFound[] | null>(null)
  const windowWidth: number = useWidth(200)

  React.useEffect(() => {
    setFoundCategory(found)
  }, [ found ])

  React.useEffect(() => {
    if (showOnlyListedFounds) {
      const listedFounds = foundCategory.fundos.filter((found: IFound) => {
        return found.isListed === true
      })

      const haveListed = foundCategory.fundos.find((fundo: IFound) => {
        return fundo.isListed === true
      })

      setHideCategory(!haveListed)

      setFoundsList(listedFounds)
    } else {
      setHideCategory(false)
      setFoundsList(found.fundos)
    }
  }, [ showOnlyListedFounds, foundCategory ])

  return (
    !hideCategory ? (
      <S.FoundsList className='founds-list py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='mb-4 mb-lg-5 pb-2 pb-md-3'>
                <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-gray-750 fw-600 f-sora mb-3'>{`${foundCategory.primeiroNome} ${foundCategory.segundoNome}`}</h2>
                <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-gray-750 fw-400 f-inter mb-0'>{foundCategory.descricao}</p>
              </div>
              {(windowWidth < WIDTH_MD) && foundsList ? (
                <DefaultCarousel
                  sm={{ items: 2 }}
                  md={{ items: foundsList ? foundsList.length : 1 }}
                  lg={{ items: foundsList ? foundsList.length : 1 }}
                  xl={{ items: foundsList ? foundsList.length : 1 }}
                >
                  {foundsList && foundsList.map((card: IFound, index: number) => (
                    <S.CardListDiv key={slugify(card.nome, String(index)) + '-mobile'} className='px-1 px-md-0'>
                      <FoundCard found={card} categoryName={foundCategory.segundoNome} slug={convertToSlug(`${foundCategory.primeiroNome} ${foundCategory.segundoNome}`, card.nome)} isListed={card.isListed} />
                    </S.CardListDiv>
                  ))}
                </DefaultCarousel>
              ) : (
                <div className='card-listing'>
                  {foundsList && foundsList.map((card: IFound, index: number) => (
                    <S.CardListDiv key={slugify(card.nome, String(index)) + '-desktop'} className='px-1 px-md-0'>
                      <FoundCard found={card} categoryName={foundCategory.segundoNome} slug={convertToSlug(`${foundCategory.primeiroNome} ${foundCategory.segundoNome}`, card.nome)} isListed={card.isListed} />
                    </S.CardListDiv>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </S.FoundsList>
    ) : null
  )
}

export default FoundsList
