import styled from 'styled-components'
import breakpoints from '../../styles/breakpoints'

interface IFoundListingStatus {
  $listed: boolean;
}

const listingStatusColors = {
  listed: '#0277BD',
  notListed: '#9575CD',
}

export const FoundsList = styled.div`
  animation: taadaa 1s forwards;
  .react-multi-carousel-track {
    @media (min-width: ${breakpoints.md}) {
      gap: 24px;
      width: 100%;
      flex-wrap: wrap;
    }
  }

  .react-multi-carousel-list {
    @media (min-width: ${breakpoints.md}) {
      overflow: initial;
    }
  }

  .card-listing {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }

  @keyframes taadaa { 
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
`

export const CardListDiv = styled.div`
  @media (min-width: ${breakpoints.md}) {
    width: 264px;
  }
`

export const FoundListingStatus = styled.span<IFoundListingStatus>`
  &::before {
    content: '';
    width: 4px;
    height: 18px;
    display: inline-block;
    border-radius: 4px;
    margin-right: 4px;
    position: relative;
    bottom: -4px;
    background-color: ${(prop: IFoundListingStatus) => prop.$listed ? listingStatusColors.listed : listingStatusColors.notListed};
  }
`
