import styled from 'styled-components'
import { gray, white } from '../../styles/colors'
import breakpoints from '../../styles/breakpoints'

export const Card = styled.div`
  display: flex;
  height: auto;
  min-height: 278px;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid ${gray[550]};
  background: ${white};

  .descricao {
    overflow: hidden; // Removendo barra de rolagem
    text-overflow: ellipsis; // Adicionando "..." ao final
    display: -webkit-box;
    -webkit-line-clamp: 3; // Quantidade de linhas
    -webkit-box-orient: vertical; 
  }

  @media (min-width: ${breakpoints.md}) {
    width: auto;
  }

  box-shadow: 0px 2px 8px 0px rgba(22, 22, 22, 0.08);

  .category-background {
    background-color: ${gray[400]};
    padding: 2px 4px;
    border-radius: 4px;
  }

  .is-listed-background {
    background-color: #E1F5FE;
    padding: 2px 4px;
    border-radius: 4px;
  }
`

export const AccessButton = styled.a`
  display: flex;
  height: 32px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #FFB46E;
  cursor: pointer;

  color: #FF7A00;
  font-weight: 700px;
  font-size: 10px;
`
