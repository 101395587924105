import React, { FunctionComponent } from 'react'
import * as S from './styles'
import FoundsList from '../FoundsList'
import slugify from 'slugify'
import { ICategoria } from '../../templates/fundosListados/types'

interface IFoundsSectionProps {
  founds: ICategoria[];
  showOnlyListedFounds: boolean;
}

const FoundsSection: FunctionComponent<IFoundsSectionProps> = ({ founds, showOnlyListedFounds }: IFoundsSectionProps) => {
  return (
    <S.FoundsListing>
      {founds.map((found: ICategoria, index: number) => (
        <FoundsList key={`${slugify(found.primeiroNome, found.segundoNome)}-${index}`} found={found} showOnlyListedFounds={showOnlyListedFounds} />
      ))}
    </S.FoundsListing>
  )
}

export default FoundsSection
